.App-logo {
  height: 30vmin;
  pointer-events: none;
  margin-top: 5rem;
  margin-bottom: 1rem;
  align-self: flex-start;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

a {
  color: white;
}
.credit {
  font-size: 0.75rem;
  color: white;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.iframe {
  margin-top: 2rem;
}
